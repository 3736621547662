import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchSegmentationStore = defineStore('searchSegmentationStore', {
  state: () => ({
    formId: null as number | null,
    formCode: null as string | null,
    segmentationType: null as string | null,
    feeding: null as string | null,
    status: null as string | null,
    statusFrom: null as string | null,
    statusTo: null as string | null,
    validFrom: null as string | null,
    validUntil: null as string | null,
    periodId: null as number | null,
    reportingObligation: null as string | null,
    difference: null as number | null,
    posNr: null as number | null,
    dairyOutIdent: null as number | null,
    dairyInIdent: null as number | null,
    delimitationOnly: null as string | null,
    modificationFrom: null as string | null,
    modificationUntil: null as string | null,
    correction: null as string | null,
    items: [
      { key: 'formId', operation: ':' },
      { key: 'formCode', operation: '~' },
      { key: 'segmentationType', operation: ':' },
      { key: 'feeding', operation: ':' },
      { key: 'status', operation: ':' },
      { key: 'statusFrom', operation: '>=' },
      { key: 'statusTo', operation: '<=' },
      { key: 'validFrom', operation: '>=' },
      { key: 'validUntil', operation: '<=' },
      { key: 'periodId', operation: ':' },
      { key: 'reportingObligation', operation: ':' },
      { key: 'difference', operation: ':' },
      { key: 'posNr', operation: ':' },
      { key: 'dairyOutIdent', operation: ':' },
      { key: 'dairyInIdent', operation: ':' },
      { key: 'delimitationOnly', operation: ':' },
      { key: 'modificationFrom', operation: '>=' },
      { key: 'modificationUntil', operation: '<=' },
      { key: 'correction', operation: ':' }
    ] as TermItemDefinition[]
  })
})
