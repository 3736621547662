import { defineStore } from 'pinia'
export const useSegmentationTypesStore = defineStore('segmentationTypesStore', {
  state: () => ({
    segmentationTypes: [
      {
        name: 'A',
        nameDe: 'A-Milch',
        nameFr: 'A-lait',
        nameIt: 'A-latte'
      },
      {
        name: 'B',
        nameDe: 'B-Milch',
        nameFr: 'B-lait',
        nameIt: 'B-latte'
      },
      {
        name: 'C',
        nameDe: 'C-Milch',
        nameFr: 'C-lait',
        nameIt: 'C-latte'
      },
      {
        name: 'BC',
        nameDe: 'B- und C-Milch',
        nameFr: 'B- et C-lait',
        nameIt: 'B- e C-latte'
      }
    ]
  })
})
